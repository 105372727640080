// COLORS
// ================================================
$primary: #009CDB;
$secondary: #79869F;
$success: #99A606;
$info: #0DCAF0;
$warning: #F7AD13;
$danger: #DC3545;
$light: #EDEEEF;
$dark: #171920;

$theme-colors: (
  "primary":    #009CDB,
  "secondary":  #79869F,
  "success":    #99A606,
  "info":       $info,
  "warning":    #F7AD13,
  "danger":     $danger,
  "light":      #EDEEEF,
  "dark":       #171920
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4
);

$body-bg: $light;
$body-color: $dark;

$text-muted: rgba(23, 35, 32, .4);

$border-color: rgba($secondary, .2);
$border-radius: .375rem;
$border-radius-sm: 0.625rem;
$border-radius-lg: 1.25rem;

$box-shadow-lg: 0 2rem 4rem rgba(52, 20, 0, .1);
// ================================================
// end of COLORS



// OPTIONS
// ================================================
$enable-negative-margins: true;
// ================================================
// end of OPTIONS



// TYPOGRAPHY
// ================================================
$font-family-base: 'Montserrat', sans-serif;
$font-weight-bolder: 900;
$line-height-base: 2;

$headings-font-weight: $font-weight-bolder;
$headings-line-height: 1.14;

$display-font-weight: $font-weight-bolder;
// ================================================
// end of TYPOGRAPHY



// LAYOUT
// ================================================
$spacer: 1.25rem;
$grid-gutter-width: 2.5rem;
// ================================================
// end of LAYOUT



// NAVBAR
$navbar-padding-y: 2.5rem;
$navbar-brand-padding-y: .125rem;

$nav-link-color: $dark;
$nav-link-hover-color: $nav-link-color;
$nav-link-font-size: .8125rem;
$nav-link-font-weight: 700;

$nav-link-padding-y: .5rem;
$navbar-nav-link-padding-x: 1.375rem;
// end of NAVBAR



// BUTTONS
// ================================================
$btn-font-size: .875rem;
$btn-line-height: 1.125;
$btn-font-weight: 600;

$btn-padding-y: 1.0625rem;
$btn-padding-x: $btn-padding-y * 2;

$btn-border-width: 2px;
// ================================================
// end of BUTTONS



// TABLES
// ================================================
$table-cell-padding-x: 1rem;
$table-cell-padding-y: 1rem;
// ================================================
// end of TABLES



// PROGRESS
// ================================================
$progress-bg: #EDEEEF;
// ================================================
// end of PROGRESS



// FORM
// ================================================
$l-form-select-filter-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$primary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
// ================================================
// end of FORM



// TOOLTIPS
// ================================================
$tooltip-font-size: .75rem;
$tooltip-max-width: 320px;
$tooltip-border-radius: 0.375rem;
$tooltip-color: $dark;
$tooltip-bg: #fff;
$tooltip-opacity: 1;
$tooltip-padding-y: $spacer * .5;
$tooltip-padding-x: $spacer * 1;
// ================================================
// end of TOOLTIPS



// ACCORDION
// ================================================
$accordion-icon-color: $primary;
// ================================================
//end of ACCORDION



// TABS
// ================================================
$nav-link-font-size: .75rem;
$nav-link-padding-x: 2rem;
$nav-tabs-link-active-color: $primary;
// ================================================
//end of TABS



// FROM
// ================================================
$input-padding-y: .75rem;
$input-padding-x: 1rem;
$input-font-size: .875rem;
// ================================================
// end of FORM
