#projectCards {
  .l-row-extend-mobile {
    @include media-breakpoint-up(lg) {
      padding-bottom: 180px;
    }
    .col-12:nth-child(2) {
      .card {
        @include media-breakpoint-up(lg) {
          transform: translateY(90px);
        }
      }
    }

    .col-12:nth-child(3) {
      .card {
        @include media-breakpoint-up(lg) {
          transform: translateY(180px);
        }
      }
    }
  }
}

#hero {
  @include media-breakpoint-down(sm) {
    .display-2 {
      font-size: 2rem;
    }

    .display-4 {
      font-size: 1.5rem;
    }
  }
}

#timeline {
  overflow: hidden;

  .card {
    @include media-breakpoint-down(lg) {
      margin-left: 50px;
    }
  }

  .row:first-child {
    &::before {
      background: transparent url('../img/design/dashed-pattern-h.svg') repeat-x top left;
      content: '';
      display: block;
      height: 1px;
      margin-left: -40px;
      position: absolute;
      right: calc(100% - 1rem);
      top: -7rem;
      width: 100vw;

      @include media-breakpoint-up(lg) {
        left: 50%;
        margin-left: -40px;
        right: auto;
        width: 80px;
      }
    }

    .l-timeline-count {
      top: 3.25rem;

      @include media-breakpoint-up(sm) {
        top: 6.75rem;
      }
    }
  }

  .row:last-child {
    .l-timeline-count {
      z-index: -1;

      &::before {
        background: transparent url('../img/design/dashed-pattern-v.svg') repeat-y top center;
        bottom: 0;
        content: '';
        display: block;
        height: 1000vh;
        position: absolute;
        transform: translateX(-50%);
        width: 1px;
      }
    }
  }

  .row:nth-child(even) {
    @include media-breakpoint-up(lg) {
      .l-timeline-count {
        &::after {
          left: 0 !important;
          right: auto;
        }
      }
    }
  }

  .row:nth-child(odd) {
    @include media-breakpoint-up(lg) {
      .l-timeline-count {
        &::after {
          left: auto;
          right: 0 !important;
        }
      }
    }
  }

  .l-timeline-count {
    height: 1.875rem;
    left: 0;
    top: 2.25rem;
    width: 1.875rem;

    &::after {
      background: transparent url('../img/design/dashed-pattern-h.svg') repeat-x top center;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 160px;
      z-index: -1;
    }

    @include media-breakpoint-up(sm) {
      top: 5.75rem;
    }

    @include media-breakpoint-up(lg) {
      height: 2.25rem;
      left: 50%;
      top: 50% !important;
      transform: translate(-50%, -50%);
      width: 2.25rem;
    }
  }

  .l-icon {
    height: 80px;
    margin-top: -80px;
    width: 80px;

    img {
      height: 40px;
    }

    @include media-breakpoint-up(sm) {
      height: 160px;
      margin-top: -160px;
      width: 160px;

      img {
        height: 64px;
      }
    }
  }

  .row + .row {
    @include media-breakpoint-down(lg) {
      margin-top: 160px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 100px;
    }
  }
}

footer {
  .accordion-button {
    background-color: transparent !important;
    color: $dark !important;
    outline: none !important;
    box-shadow: none !important;

    @include media-breakpoint-up(md) {
      pointer-events: none;
    }

    &::after {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

// TEMPORARY FIX
#investmentsCarousel {
  .glide__slide:last-child .card {
    //min-height: 648px;

    img:nth-child(2) {
      height: 2rem;
    }

    @include media-breakpoint-up(sm) {
      //min-height: 748px;

      img:nth-child(2) {
        height: 3rem;
        //margin-top: 3rem;
      }
    }

    @include media-breakpoint-up(md) {
      //min-height: 836px;

      img:nth-child(2) {
        height: 3rem;
        //margin-top: 3rem;
      }
    }

    @include media-breakpoint-up(lg) {
      //min-height: 946px;
    }

    @include media-breakpoint-up(xl) {
      //min-height: 462px;

      img:nth-child(2) {
        height: 1.5rem;
      }
    }
  }
}
// end of TEMPORARY FIX

.tooltip {
  box-shadow: $box-shadow-lg;
}
