.fw-medium {
  font-weight: 500;
}

.fw-semi-bold {
  font-weight: 600;
}

.scrollDown {
  width: 40px;

  @include media-breakpoint-up(xl) {
    width: 72px;
  };
}

.l-line-height-1-5 {
  line-height: 1.5;
}

body {
  background-image: url('../img/design/bg-desktop.svg');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
}

.shadow-xl {
  box-shadow: 0 50px 80px rgba(0, 0, 0, .1);
}

.l-list-dotted {
  li {
    position: relative;

    &::before {
      background: transparent url('../img/design/dash-pattern.svg') repeat-x top left;
      bottom: 9px;
      //border: 1px dotted $text-muted;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  li + li {
    margin-top: .5rem;
  }
}

.l-row-extend-mobile {
  @include media-breakpoint-down(sm) {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
}

// ANIMATION
[data-animate] {
  visibility: hidden;

  &.animate__animated {
    visibility: visible !important;
  }

  &.js-animated {
    visibility: visible !important;
  }
}
// end of ANIMATION

// COLUMN COUNT
.l-column-count-2 {
  @include media-breakpoint-up(lg) {
    column-count: 2;
  }
}
// end of COLUMN COUNT
