.l-chart-legend {
  color: $secondary;
  list-style: none;
  font-size: .75rem;

  ul {
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: space-between;
    }
  }

  li {
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  li + li {
    margin-left: 30px;
  }

  span {
    border-radius: 9px;
    display: inline-block;
    height: 18px;
    margin-right: 10px;
    width: 18px;
  }

  p {
    margin: 0;
  }
}

.l-progress-list {
  li + li {
    margin-top: 1.5rem;
  }

  li > div:first-child {
    order: 1;
  }

  li > div:last-child {
    order: 2;
  }

  li > div:first-child,
  li > div:last-child {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    li > div:first-child,
    li > div:last-child {
      width: 20%;
    }

    li > div:last-child {
      order: 3;
    }
  }
}

.l-progress {
  display: flex;
  height: 2.625rem;
  overflow: hidden;
  order: 3;
  font-size: 0.75rem;
  background-color: #EDEEEF;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100% !important;

  @include media-breakpoint-up(lg) {
    order: 2;
    width: 60% !important;
  }

  .progress-bar {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    overflow: visible;

    &[aria-valuenow="0"]::before {
      display: none !important;
    }

    &[aria-valuenow="0"]::after {
      content: '0%' !important;
    }

    &[aria-valuenow="88"],
    &[aria-valuenow="89"],
    &[aria-valuenow="90"],
    &[aria-valuenow="91"],
    &[aria-valuenow="92"],
    &[aria-valuenow="93"],
    &[aria-valuenow="94"],
    &[aria-valuenow="95"],
    &[aria-valuenow="96"],
    &[aria-valuenow="97"],
    &[aria-valuenow="98"],
    &[aria-valuenow="99"],
    &[aria-valuenow="100"] {
      &::after {
        color: $white;
        left: auto;
        right: 1rem;
        transform: translateY(-50%);
      }
    }

    &::before,
    &::after  {
      font-size: .875rem;
      font-weight: 700;
      position: absolute;
      top: 50%;
    }

    @include media-breakpoint-up(md) {
      &::before,
      &::after  {
        font-size: 1.125rem;
      }
    }

    &::before {
      content: attr(data-percent-value);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      color: $dark;
      content: attr(data-value);
      left: calc(100% + 1rem);
      transform: translateY(-50%);
    }
  }
}
