.l-table-list {
  .table > :not(:first-child) {
    border-top: none;
  }

  img {
    height: 3.75rem;
    object-fit: cover;
    width: 3.75rem;
  }

  th,
  td {
    line-height: 1.2;
    vertical-align: middle;
  }

  th {
    font-size: 0.6875rem;
  }

  td {
    white-space: nowrap;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
