.navbar {
  @include media-breakpoint-down(xl) {
    background-color: $light;
    padding-bottom: .5rem;
    padding-top: .5rem;

    > .container {
      max-width: 100%;
    }
  }

  .dropdown-toggle {
    &.show {
      text-decoration: underline;

      &::after {
        border-top-color: $primary;
      }
    }

    &.active {
      &.show {
        color: $dark;

        &::after {
          border-top-color: $primary;
        }
      }

      &::after {
        border-top-color: $dark;
      }

      &:hover {
        &::after {
          border-top-color: $primary;
        }
      }
    }

    &::after {
      margin-left: 10px;
    }
  }

  .dropdown-item {
    font-size: .8125rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .nav-link {
    &.active {
      color: $primary;

      &:hover {
        color: $dark;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .btn {
    padding-bottom: .75rem;
    padding-top: .75rem;
  }
}

.navbar-brand {
  img {
    height: 20px;

    @include media-breakpoint-up(xl) {
      height: 24px;
    }
  }
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus,
.nav-link {
  outline: none;
  box-shadow: none;
}
