.glide__bullets {
  background-color: $secondary;
  border-radius: 2px;
  overflow: visible;
}

.glide__arrow--right,
.glide__arrow--left {
  border: none;
  box-shadow: none;
  padding: 0;

  &:hover {
    .fill {
      fill: $primary;
    }

    .icon {
      fill: white;
    }
  }

  svg {
    width: 70px;

    .fill,
    .icon {
      transition: all .1s ease-in-out;
    }
  }
}

.glide__bullet {
  background-color: $primary;
  border: none;
  border-radius: 2px;
  box-shadow: none;
  height: 2px;
  margin: 0;
  width: 40px;
}

.glide__bullet--active ~ button {
  background-color: $secondary !important;
}

.glide__bullet--active,
.glide__bullet:hover,
.glide__bullet:focus {
  background-color: $primary !important;
  border: none;
}

#investmentsCarousel {
  .glide__arrow--right,
  .glide__arrow--left {
    margin-top: -40px;
  }

  .glide__arrow--right {
    right: -140px;
  }

  .glide__arrow--left {
    left: -140px;
  }

  .glide__slide {
    transition: all .2s ease-in-out;
  }

  .glide__slide:not(.glide__slide--active) {
    box-shadow: 0 2rem 4rem rgba(52, 20, 0, .04);;
    opacity: .3;
  }
}

#featuresCarousel {
  .glide__arrow--right {
    right: -70px;
    margin-top: -55px;
  }

  .glide__slide {
    > .card {
      @include media-breakpoint-up(xl) {
        margin: 0 40px 40px;

        &::before {
          background-color: #e2e2e2;
          border-radius: 20px;
          content: '';
          display: block;
          height: 100%;
          left: -40px;
          position: absolute;
          top: 40px;
          width: calc(100% + 80px);
          z-index: -1;
        }
      }
    }
  }

  .l-card__bg-img {
    left: 50%;
    opacity: .1;
    top: 50%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-up(xl) {
      height: 70%;
    }
  }

  .l-card__thumb {
    height: 100px;
    width: 100px;

    img {
      height: 2.5rem;
    }

    @include media-breakpoint-up(xl) {
      height: 160px;
      width: 160px;

      img {
        height: 4rem;
      }
    }
  }
}

.glide__slide,
.glide__slides {
  touch-action: pan-y;
}
