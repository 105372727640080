.datepicker {
  display: none;
}

.datepicker.active {
  display: block;
}

.datepicker-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-top: 4px;
}

.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: inline-block;
  border-radius: 0.25rem;
  background-color: #fff;
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.datepicker-picker span {
  display: block;
  flex: 1;
  border: 0;
  border-radius: 0.25rem;
  cursor: default;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker-main {
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

.datepicker-grid,
.datepicker-view .days-of-week,
.datepicker-view,
.datepicker-controls {
  display: flex;
}

.datepicker-grid {
  flex-wrap: wrap;
}

.datepicker-view .days .datepicker-cell,
.datepicker-view .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}

.datepicker-cell,
.datepicker-view .week {
  height: 2.25rem;
  line-height: 2.25rem;
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}

.datepicker-controls .btn {
  border-color: #f8f9fa;
  background-color: #fff;
}

.datepicker-controls .btn:hover {
  border-color: #dae0e5;
  background-color: #e2e6ea;
  color: #000;
}

.datepicker-controls .btn:focus {
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
  background-color: #e2e6ea;
  color: #000;
}

.datepicker-controls .btn:disabled {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #000;
}

.datepicker-controls .btn:not(:disabled):active {
  border-color: #d3d9df;
  background-color: #dae0e5;
  color: #000;
}

.datepicker-controls .btn:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.datepicker-header .datepicker-controls .btn {
  border-color: transparent;
  font-weight: bold;
}

.datepicker-footer .datepicker-controls .btn {
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 0.2rem;
  width: 100%;
  font-size: 0.875rem;
}

.datepicker-controls .view-switch {
  flex: auto;
}

.datepicker-controls .prev-btn,
.datepicker-controls .next-btn {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  width: 2.25rem;
}

.datepicker-controls .prev-btn.disabled,
.datepicker-controls .next-btn.disabled {
  visibility: hidden;
}

.datepicker-view .dow {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.9375rem;
  font-weight: 700;
}

.datepicker-view .week {
  width: 2.25rem;
  color: #dee2e6;
  font-size: 0.875rem;
}

@media (max-width: 22.5rem) {
  .datepicker-view .week {
    width: 1.96875rem;
  }
}

.datepicker-grid {
  width: 15.75rem;
}

@media (max-width: 22.5rem) {
  .calendar-weeks + .days .datepicker-grid {
    width: 13.78125rem;
  }
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.datepicker-cell.focused:not(.selected) {
  background-color: #e2e6ea;
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: #0d6efd;
  color: #fff;
  font-weight: 600;
}

.datepicker-cell.disabled {
  color: #adb5bd;
}

.datepicker-cell.prev:not(.disabled),
.datepicker-cell.next:not(.disabled) {
  color: color("gray");
}

.datepicker-cell.prev.selected,
.datepicker-cell.next.selected {
  color: #e6e6e6;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #f8f9fa;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #f1f3f5;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e2e6ea;
}

.datepicker-cell.today:not(.selected) {
  background-color: #20c997;
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #1ebe8f;
}

.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #6c757d;
  color: #fff;
}

.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #666f76;
}

.datepicker-cell.range-start {
  border-radius: 0.25rem 0 0 0.25rem;
}

.datepicker-cell.range-end {
  border-radius: 0 0.25rem 0.25rem 0;
}

.datepicker-cell.range {
  border-radius: 0;
  background-color: #e9ecef;
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #e2e6ea;
}

.datepicker-cell.range.disabled {
  color: #cbd3da;
}

.datepicker-cell.range.focused {
  background-color: #dadfe4;
}

.datepicker-view.datepicker-grid .datepicker-cell {
  height: 4.5rem;
  line-height: 4.5rem;
}

.datepicker-input.in-edit {
  border-color: #6da8fe;
}

.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(109, 168, 254, 0.2);
}
