.card-body {
  .h-100 & {
    flex: inherit;
  }
}

.l-hover-card {
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: white !important;
  }
}

.l-rounded {
  > .col-12:first-child {
    > .card {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-bottom: none;
    }
  }

  > .col-12:nth-child(2) {
    > .card {
      border-radius: 0 !important;
      border-bottom: none !important;
    }
  }

  > .col-12:nth-child(3) {
    > .card {
      border-radius: 0 !important;
      border-bottom: none;
    }
  }

  > .col-12:last-child {
    > .card {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    > .col-12:first-child {
      > .card {
        border-top-right-radius: 0 !important;
        border-bottom: none !important;
        border-right: none !important;
      }
    }

    > .col-12:nth-child(2) {
      > .card {
        border-top-right-radius: $border-radius-sm !important;
        border-top-left-radius: 0 !important;
        border-bottom: none !important;
      }
    }

    > .col-12:nth-child(3) {
      > .card {
        border-bottom-left-radius: $border-radius-sm !important;
        border-bottom-right-radius: 0 !important;
        border-right: none !important;
        border-bottom: 1px solid $border-color;
      }
    }

    > .col-12:last-child {
      > .card {
        border-bottom-left-radius: 0 !important;
      }
    }

    > .col-12:first-child,
    > .col-12:nth-child(2) {
      > .card {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    > .col-12:last-child,
    > .col-12:nth-child(3) {
      > .card {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }
}
